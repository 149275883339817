import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const banner = lazy(() => import('../../container/pages/banner'));
const addBanner = lazy(() => import('../../container/pages/addBanner'));
const editBanner = lazy(() => import('../../container/pages/editBanner'));


function PagesRoute() {
  const { path } = useRouteMatch();
  
  return (
    <Switch>
      <Route path={`${path}/addbanner`} component={addBanner} />
      <Route path={`${path}/editbanner`} component={editBanner} />
      <Route path={`${path}/listing`} component={banner} />
    </Switch>
  );
}

export default PagesRoute;
