import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Dashboard from './dashboard';

import Users from './users';
import customer from './customer';
import Post from './post';
import question from './question';
import banner from './banner';

import withAdminLayout from '../../layout/withAdminLayout';

const Myprofile = lazy(() => import('../../container/profile/myProfile/Index'));

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />
        <Route path={`${path}/users`} component={Users} />
        <Route path={`${path}/customer`} component={customer} />
        <Route path={`${path}/post`} component={Post} />
        <Route path={`${path}/question`} component={question} />
        <Route path={`${path}/banner`} component={banner} />
        <Route path={`${path}/profile/myProfile`} component={Myprofile} />
      </Suspense>
    </Switch>
  );
}

export default withAdminLayout(Admin);
